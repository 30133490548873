<script setup>
/* Imports */
import {
  computed,
  inject,
} from 'vue';

/* Inject */
const showGarminMaps = inject('showGarminMaps');
const isHeatmap = inject('isHeatmap');

/* Props */
const props = defineProps({
  translations: {
    type: Object,
    default: () => ({}),
  },
  chartType: {
    type: String,
    default: '',
  },
});

/* Emits */
const emit = defineEmits(['changeChartType']);

/* Methods */
const changeChartViewType = (event) => {
  emit('changeChartType', event?.target?.value);
};

/* Computed - Translations */
const navChartTypeTranslation = computed(() => props.translations?.ITFE_MARINE_MAPS_CHART_TYPE_ONE);
const fishChartTypeTranslation = computed(() => props.translations?.ITFE_MARINE_MAPS_CHART_TYPE_TWO);
const sonarChartTypeTranslation = computed(() => props.translations?.ITFE_MARINE_MAPS_CHART_TYPE_SONAR);
const nauticalChartTypeTranslation = computed(() => props.translations?.ITFE_MARINE_MAPS_CHART_TYPE_NAUTICAL);
const mapChartTypeHeading = computed(() => props.translations?.ITFE_MARINE_MAPS_CHART_TYPE_HEADING);
</script>

<template>
  <div
    v-if="!isHeatmap"
    class="map-chart-type"
    data-testid="map-chart-type"
  >
    <g-heading
      v-if="mapChartTypeHeading"
      class="map-chart-type__heading"
      data-testid="map-chart-type__heading"
      :content="mapChartTypeHeading"
      size="4"
    />
    <template v-if="showGarminMaps">
      <g-radio
        v-if="navChartTypeTranslation"
        :label="navChartTypeTranslation"
      >
        <input
          name="chartViewer"
          type="radio"
          value="nav"
          :checked="props.chartType === 'nav'"
          @change="changeChartViewType($event)"
        >
      </g-radio>
      <g-radio
        v-if="fishChartTypeTranslation"
        :label="fishChartTypeTranslation"
      >
        <input
          name="chartViewer"
          type="radio"
          :value="'fish'"
          :checked="props.chartType === 'fish'"
          @change="changeChartViewType($event)"
        >
      </g-radio>
    </template>
    <template v-if="!showGarminMaps">
      <g-radio
        v-if="nauticalChartTypeTranslation"
        :label="nauticalChartTypeTranslation"
      >
        <input
          name="chartViewer"
          type="radio"
          :value="'nautical'"
          :checked="props.chartType === 'nautical'"
          @change="changeChartViewType($event)"
        >
      </g-radio>
      <g-radio
        v-if="sonarChartTypeTranslation"
        :label="sonarChartTypeTranslation"
      >
        <input
          name="chartViewer"
          type="radio"
          value="sonar"
          :checked="props.chartType === 'sonar'"
          @change="changeChartViewType($event)"
        >
      </g-radio>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@include map-settings('map-chart-type');
</style>
