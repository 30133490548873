<script setup>
/* Imports */
import {
  computed,
  inject,
} from 'vue';
import router from '../router/router.js';

/* Helpers */
import {
  mapGetters,
  mapActions,
  stringTemplate,
} from '../helpers/mainHelpers.js';

/* Components */
import Results from '../components/Results.vue';
import Search from '../components/Search.vue';
import MapNumberOfUpdates from '../components/MapNumberOfUpdates.vue';

/* Inject */
const translations = inject('translations');
const lastViewedMapBrand = inject('lastViewedMapBrand');
const isWorldwideUpdates = inject('isWorldwideUpdates');
const isLoading = inject('isLoading');
const isHeatmap = inject('isHeatmap');

/* Store - Getters */
const {
  getIsDrawerVisible: isDrawerVisible,
  getFormattedAddress: formattedAddress,
  getDisplayMobileSearch: displayMobileSearch,
  getDisplayMobileResults: displayMobileResults,
  getActiveBrand: activeBrand,
} = mapGetters();

/* Store - Actions */
const {
  performSearch: search,
  setIsSearched,
  removePolygon,
  setActiveLake,
} = mapActions();

/* eslint-disable max-len */
const tapHereURL = (type) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('maps', type);
  searchParams.set('overlay', false);
  const updatedQueryParams = searchParams.toString();
  return `${window.location.pathname}?${updatedQueryParams}`;
};

const tapHere = computed(() => (
  activeBrand.value === 'garmin'
    ? translations.value?.ITFE_MARINE_MAPS_TAP_HERE
    : translations.value?.ITFE_MARINE_MAPS_TAP_HERE_ANOTHERBRAND
));
const garminCompatVerbiage = computed(() => stringTemplate(translations.value?.ITFE_MARINE_MAPS_GARMIN_COMPATIBLE_VERBIAGE, { LINK: `<a class="map-brand-link" data-active-brand="another-brand" href="${tapHereURL('another-brand')}">${tapHere.value}</a>` }));
const anotherBrandCompatVerbiage = computed(() => stringTemplate(translations.value?.ITFE_MARINE_MAPS_ANOTHER_BRAND_COMPATIBLE_VERBIAGE, { LINK: `<a class="map-brand-link" data-active-brand="garmin" href="${tapHereURL('garmin')}">${tapHere.value}</a>` }));
/* eslint-enable max-len */

/* Computed - should show the map number of updates */
const displayMobileNumberOfMapUpdates = computed(() => isWorldwideUpdates.value && isHeatmap.value && displayMobileResults.value);

const submitSearch = async () => {
  // If the user comes to Marine Maps with a query param in the URL (without populating the input field) and then searches from input
  await search(encodeURIComponent(formattedAddress.value));
  setIsSearched(true);
  removePolygon();
  // If the user has an active lake and searches from input for a new location
  setActiveLake(null);
};

const handleLinkSelection = async (e) => {
  if (e?.target?.className === 'map-brand-link') {
    e.preventDefault();
    const newActiveBrand = e?.target?.getAttribute('data-active-brand');

    // update maps query param with new product filter selection
    const existingQueryParams = Object.fromEntries(new URLSearchParams(window?.location?.search));
    // router.push is needed for back button functionality
    router.push({
      query: { ...existingQueryParams, maps: newActiveBrand },
    });
    // Save selected map brand to local storage
    lastViewedMapBrand.value = newActiveBrand;

    // TODO - need to get products to refetch
    await submitSearch();
  }
};
</script>

<template>
  <div
    id="drawer-mobile"
    :class="[
      'drawer',
      {'drawer--hidden' : !isDrawerVisible },
    ]"
    data-testid="drawer-mobile"
  >
    <div
      :class="[
        'drawer__results',
        'drawer__results__background']
      "
    >
      <div
        v-if="displayMobileSearch"
        class="drawer__padding__search"
      >
        <Search />
      </div>
      <div
        v-if="displayMobileResults"
        class="drawer__padding__results-wrap"
      >
        <div
          v-if="displayMobileNumberOfMapUpdates"
          class="drawer__padding__number-of-updates"
        >
          <MapNumberOfUpdates />
        </div>
        <g-copy
          class="drawer__padding__verbiage"
          @click="handleLinkSelection"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            v-if="activeBrand === 'garmin'"
            v-html="garminCompatVerbiage"
          />
          <span
            v-if="activeBrand === 'another-brand'"
            v-html="anotherBrandCompatVerbiage"
          />
          <!-- eslint-enable vue/no-v-html -->
        </g-copy>
        <Results
          :class="[
            {'drawer__padding__results' : !isLoading },
          ]"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.drawer {
  width: 100%;
  background: transparent;
  transition: margin-right 0.5s cubic-bezier(0.52, -0.57, 0.42, 1.57) 0s;
  display: flex;
  -webkit-overflow-scrolling: touch;

  &--hidden {
    display: none;
  }

  &__map-number-of-updates {
    padding-bottom: 2rem;
  }

  &__padding {

    &__search {
      padding: 1.5rem 1.25rem;
    }

    &__number-of-updates {
      border-bottom: 2px solid $color-gray-85;
      margin-bottom: 1rem;
    }

    &__verbiage {
      margin-bottom: 0;
    }

    &__results {
      padding-top: 1rem;

      &-wrap {
        padding: 1rem 1.25rem;
        position: relative;
      }
    }

    &__number-of-updates + &__results-wrap {
      padding-top: 0.5rem;
    }
  }

  &__results {
    background-color: transparent;
    width: 100%;
    height: 100%;

    &__background {
      background-color: $color-gray-95;
    }
  }
}
</style>
