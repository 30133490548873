/**
 * @typedef {import('vue').default} Vue
 */
import * as Sentry from '@sentry/vue';
import pkg from '../../package.json';

/**
 * Initializes Sentry for error tracking in a Vue.js application.
 * @param {Vue} app - The Vue instance representing the application.
 */
export const sentry = (app) => {
  // Sentry - Only run in production
  if (window?.location?.host === 'maps.garmin.com') {
    Sentry.init({
      app,
      dsn: 'https://afdc7566debf4018b3273bc5c075f587@o430870.ingest.sentry.io/6324207',
      release: `${pkg.name}@${pkg.version}`,
      environment: 'production',
      logErrors: true,
    });
  }
};

export default sentry;
