<script setup>
/* Imports */
import { inject } from 'vue';

/* Helpers */
import { mapActions } from '../helpers/mainHelpers.js';

/* Icons */
import '@garmin/regalia/icons/search/icon';

/* Inject */
const translations = inject('translations');

/* Store - Actions */
const {
  setDisplayMobileSearch,
  setDisplayMobileResults,
} = mapActions();

const displaySearch = () => {
  setDisplayMobileSearch(true);
  setDisplayMobileResults(false);
};
</script>

<template>
  <button
    class="search-btn"
    data-testid="search-btn"
    :aria-label="translations.ITFE_MARINE_MAPS_INPUT_SEARCH"
    @click="displaySearch"
    @keydown.enter="displaySearch"
  >
    <g-icon-search class="search-btn__icon" />
  </button>
</template>

<style lang="scss" scoped>
.search-btn {
  background: $color-white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  @include drop-shadow();
  width: 3.125rem; // 50px
  height: 3.125rem; // 50px

  @include breakpoint('sm') {
    display: none;
  }
}
</style>

<style lang="scss">
.search-btn {

  &__icon > svg {
    height: 1.5625rem; // 25px
    fill: $color-black;
  }
}
</style>
