/* Imports */
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import 'string_score';
import mapSearchQuery from '../../graphqlServer/queries/mapSearchQuery.js';
import lakesSearchQuery from '../../graphqlServer/queries/lakesSearchQuery.js';
import promotedPricingQuery from '../../graphqlServer/queries/promotedPricingQuery.js';
import lakeByNameSearchQuery from '../../graphqlServer/queries/lakeByNameSearchQuery.js';
import { getDistanceFromLatLong } from '../helpers/mainHelpers.js';
import { flow } from '../helpers/flow.js';

export const sortMaps = (maps) => maps.sort((a, b) => {
  const difference = a.sortPrice - b.sortPrice;
  if (difference === 0) {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  }
  return difference;
});

const prepareMaps = flow(sortMaps);

export default {
  state: {
    searchQuery: '',
    formattedAddress: 'Lebanon, KS 66952, USA', // Default map location
    isError: false,
    noResults: false,
    isLoading: false,
    maps: [],
    lakes: [],
    lakesByRelevance: [],
    activeLake: null,
    showLakeInfo: false,
    showLakeFeatures: false,
    searchType: 'location',
    currencyCode: window?.AppData?.currencyCode || 'NA',
  },
  getters: {
    getSearchQuery: (currentState) => currentState.searchQuery,
    getFormattedAddress: (currentState) => currentState.formattedAddress,
    getMaps: (currentState) => currentState.maps,
    getLakes: (currentState) => currentState.lakes,
    getActiveLake: (currentState) => currentState.activeLake,
    getIsError: (currentState) => currentState.isError,
    getNoResults: (currentState) => currentState.noResults,
    getIsLoading: (currentState) => currentState.isLoading,
    getShowLakeInfo: (currentState) => currentState.showLakeInfo,
    getShowLakeFeatures: (currentState) => currentState.showLakeFeatures,
    getLakesByRelevance: (currentState) => currentState.lakesByRelevance,
    getSearchType: (currentState) => currentState.searchType,
    // NOTE: currentState is left on purpose even though it's not used -> it breaks reactivity if removed :-)
    getIsGarminMap: (currentState, rootGetters) => {
      const {
        getActiveBrand: activeBrand,
      } = rootGetters;

      return activeBrand === 'garmin';
    },
    getCurrencyCode: (currentState) => currentState.currencyCode,
  },
  mutations: {
    setSearchQuery(state, searchQuery) {
      state.searchQuery = searchQuery;
    },
    setFormattedAddress(state, formattedAddress) {
      state.formattedAddress = formattedAddress;
    },
    setMaps(state, maps) {
      state.maps = maps;
    },
    setLakes(state, lakes) {
      state.lakes = lakes;
    },
    setActiveLake(state, lake) {
      state.activeLake = lake;
    },
    setIsError(state, isError) {
      state.isError = isError;
    },
    setNoResults(state, noResults) {
      state.noResults = noResults;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setShowLakeInfo(state, showLakeInfo) {
      state.showLakeInfo = showLakeInfo;
    },
    setShowLakeFeatures(state, payload) {
      state.showLakeFeatures = payload;
    },
    setLakesByRelevance(state, payload) {
      state.lakesByRelevance = payload;
    },
    setSearchType(state, payload) {
      state.searchType = payload;
    },
  },
  actions: {
    setSearchQuery({ commit }, payload) {
      commit('setSearchQuery', payload);
    },
    setFormattedAddress({ commit }, payload) {
      commit('setFormattedAddress', payload);
    },
    async searchMap({
      commit,
      rootState,
      rootGetters,
      state,
      dispatch,
    }) {
      await rootGetters;
      const {
        getGraphQLEndpoint: graphQLEndpoint,
        getPcfApiUrl: pcfApiUrl,
        getIsGarminMap: isGarminMap,
      } = rootGetters;

      // Get GraphQL endpoint to construct Client + env selector based on current environment
      const uri = pcfApiUrl ? `${pcfApiUrl}${graphQLEndpoint}` : graphQLEndpoint;

      const { latLng } = rootState.map;
      const { lat, lng } = latLng;
      const locale = window?.location?.pathname.split('/')[1];

      const cache = new InMemoryCache();
      const client = new ApolloClient({ cache, uri });

      commit('setNoResults', false);
      commit('setIsLoading', true);
      commit('setIsError', false);

      try {
        const [mapsResponse, lakesResponse] = await Promise.allSettled([client.query({
          query: mapSearchQuery,
          variables: {
            lat: lat.toString(),
            long: lng.toString(),
            locale,
            isGarminMap,
          },
        }), client.query({
          query: lakesSearchQuery,
          variables: {
            lat: lat.toString(),
            long: lng.toString(),
            locale,
            isGarminMap,
          },
        })]);

        let mapData = mapsResponse?.value?.data?.getMapCardsByLatLong || [];
        const lakesData = lakesResponse?.value?.data?.getLakesByLatLong || [];

        // Filter maps that are not valid
        mapData = mapData.filter((map) => !!map.sku);

        // Query promoted pricing
        const promotedPrices = await client.query({
          query: promotedPricingQuery,
          variables: {
            skus: mapData.map((map) => map.priceSku),
            locale,
          },
        });

        if (promotedPrices?.data?.getPromotedPricing) {
          mapData.forEach((map, index) => {
            const promoPrice = promotedPrices?.data?.getPromotedPricing?.find(
              (obj) => obj.sku === map.priceSku,
            );
            if (!promoPrice) {
              return;
            }

            const { formattedListPrice, formattedSalePrice } = promoPrice;

            // If the prices are equal, do nothing and continue to the
            // next map object in the array of maps to update
            // This is to prevent the price from being formatted twice. Hallelujah! 🙌
            if (formattedSalePrice === formattedListPrice) {
              return;
            }

            // Create a new object with existing properties and additional ones
            const updatedMap = {
              ...map,
              formattedListPrice,
              formattedSalePrice,
            };

            // Update the original object in the array with the new object that has the additional properties
            mapData[index] = updatedMap;
          });
        }

        const maps = prepareMaps(mapData);

        let lakeResult;

        if (state.searchQuery) {
          if (state.searchType === 'location') {
            lakeResult = lakesData?.find(
              (lake) => lake.name.toLowerCase() === state.searchQuery.toLowerCase(),
            );
          }

          if (state.searchType === 'lakes') {
            const { lakesByRelevance } = state;
            // Get most relevant lake result
            /* eslint-disable-next-line prefer-destructuring */
            lakeResult = lakesByRelevance[0];
          }

          if (lakeResult) {
            // Center the map onto the specific lake
            this.dispatch('setLatLng', {
              lat: lakeResult.center_lat,
              lng: lakeResult.center_lon,
            });

            // Zoom the map to level 10
            this.dispatch('setZoom', 10);

            // Pre-select the lake and show details
            dispatch('setActiveLake', lakeResult);
            dispatch('setShowLakeInfo', true);
            dispatch('setShowLakeFeatures', true);
          }
        }

        commit('setMaps', maps);
        commit('setLakes', lakesData);
        dispatch('setResultsStates');
        commit('setIsLoading', false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        commit('setIsError', true);
        commit('setIsLoading', false);
      }
    },
    setResultsStates({ commit, state }) {
      const { maps } = state;
      const noResults = !maps.length > 0;

      if (noResults) commit('setNoResults', true);
    },
    setNoResultsStates({ commit }) {
      commit('setNoResults', true);
      commit('setIsLoading', false);
    },
    performSearch({ state, dispatch }, payload) {
      if (state.searchType === 'lakes') {
        return dispatch('lakeSearch', payload).then((searchResults) => {
          if (searchResults.length) {
            const relevantLake = searchResults[0];

            this.dispatch('updateMapInfo', {
              lat: relevantLake.center_lat,
              lng: relevantLake.center_lon,
              bounds: null,
            });
            this.dispatch('setShowLocationMarker', true);
          } else {
            // No results
            dispatch('noResults');
            this.dispatch('setShowLocationMarker', false);
          }
        });
      }

      // call geoencoding
      let lat = 0;
      let lng = 0;
      return dispatch('addressSearch', payload).then(
        (searchResults, status) => {
          if (searchResults) {
            let index = 1;
            if (searchResults.length < 2) {
              index = 0;
            }
            lat = searchResults[index].geometry.location.lat();
            lng = searchResults[index].geometry.location.lng();
            this.dispatch('updateMapInfo', {
              lat,
              lng,
              bounds: searchResults[index].geometry.bounds,
            });
            dispatch(
              'setFormattedAddress',
              searchResults[index].formatted_address,
            );
            this.dispatch('setShowLocationMarker', true);
          } else {
            // no results
            dispatch('noResults');
            dispatch('setFormattedAddress', status);
            this.dispatch('setShowLocationMarker', false);
          }
        },
      );
    },
    addressSearch({ rootGetters }, payload) {
      return new Promise((resolve) => {
        const googleMapsApi = rootGetters.getGoogleMapsApi;
        const geocoder = new googleMapsApi.maps.Geocoder();
        geocoder.geocode(
          { address: decodeURIComponent(payload) },
          (results, status) => {
            if (status === 'OK') {
              resolve(results);
            } else {
              resolve(null);
            }
          },
        );
      });
    },
    lakeSearch({
      rootState,
      rootGetters,
      state,
      dispatch,
    }) {
      const {
        getGraphQLEndpoint: graphQLEndpoint,
        getPcfApiUrl: pcfApiUrl,
        getIsGarminMap: isGarminMap,
      } = rootGetters;
      // Get GraphQL endpoint to construct Client + env selector based on current environment
      const uri = pcfApiUrl ? `${pcfApiUrl}${graphQLEndpoint}` : graphQLEndpoint;
      const { latLng } = rootState.map;
      const { lat, lng } = latLng;

      let lakesByRelevance;

      const cache = new InMemoryCache();
      const client = new ApolloClient({ cache, uri });

      return new Promise((resolve) => {
        client
          .query({
            query: lakeByNameSearchQuery,
            variables: {
              name: state.searchQuery,
              isGarminMap,
            },
          })
          .then((results) => {
            const lakesData = results?.data?.getLakesByName;

            /**
             * Generates distance key on lakeData object
             * Distance is calculated via getDistanceFromLatLong
             * @param {object} lakeData - lake data object
             * @returns {object}        - lakeData object with DISTANCE key
             */
            const setDistance = (lakeData) => {
              const calculatedDistance = getDistanceFromLatLong(
                lat,
                lng,
                lakeData.center_lat,
                lakeData.center_lon,
              );
              return { ...lakeData, ...{ distance: calculatedDistance } };
            };

            /**
             * Generates score key on lakeData object
             * Score is calculated by fuzzy match on searchQuery string compared against lake results
             * @param {object} lakeData - lake data object
             * @returns {object}        - lakeData object with SCORE key
             */
            const setScore = (lakeData) => {
              const calculatedScore = lakeData.name.score(state.searchQuery);
              return { ...lakeData, ...{ score: calculatedScore } };
            };

            /**
             * Sorts lakeData objects by lake name match,
             * if multiple lake results have the same name,
             * sort those by distance where closest comes first
             * @param {object} locationA - lakeData object
             * @param {object} locationB - lakeData object
             * @returns {number}         - sort order
             */
            const sortByRelevance = (locationA, locationB) => {
              if (locationA.score === locationB.score) {
                return locationA.distance - locationB.distance;
              }
              return locationB.score - locationA.score;
            };

            if (lakesData) {
              lakesByRelevance = lakesData
                .map(setDistance)
                .map(setScore)
                .sort(sortByRelevance);
              dispatch('setLakesByRelevance', lakesByRelevance);
              resolve(lakesByRelevance);
            } else {
              resolve(null);
            }
          });
      });
    },
    noResults({ dispatch }) {
      dispatch('setLocationMarker', null);
      dispatch('setNoResultsStates');
    },
    setActiveLake({ commit }, payload) {
      commit('setActiveLake', payload);
    },
    setShowLakeInfo({ commit }, payload) {
      commit('setShowLakeInfo', payload);
    },
    setShowLakeFeatures({ commit }, payload) {
      commit('setShowLakeFeatures', payload);
    },
    setLakesByRelevance({ commit }, payload) {
      commit('setLakesByRelevance', payload);
    },
    setSearchType({ commit }, payload) {
      commit('setSearchType', payload);
    },
  },
};
