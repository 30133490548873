<script setup>
/* Imports */
import {
  computed,
  ref,
  inject,
} from 'vue';

/* Props */
const props = defineProps({
  translations: {
    type: Object,
    default: () => ({}),
  },
  safetyDepth: {
    type: [String, Number],
    default: '',
  },
  depthUnit: {
    type: String,
    default: '',
  },
});

/* Inject */
const isHeatmap = inject('isHeatmap');

/* State */
const state = ref({
  setMinValue: 0,
});

/* Emits */
const emit = defineEmits(['changeSafetyDepth']);

const setMaxValue = computed(() => {
  const maxValues = {
    m: '10',
    fath: '5.5',
    ft: '33',
  };

  return maxValues[props.depthUnit] || '';
});

const setStepValue = computed(() => {
  const stepValues = {
    m: '0.1',
    fath: '0.1',
    ft: '1',
  };

  return stepValues[props.depthUnit] || '';
});

const translatedDepthUnit = computed(() => {
  const unitTranslations = {
    m: props.translations.ITFE_MARINE_MAPS_METERS_ABBREVIATION,
    fath: props.translations.ITFE_MARINE_MAPS_FATHOMS_ABBREVIATION,
    ft: props.translations.ITFE_MARINE_MAPS_FEET_ABBREVIATION,
  };

  return unitTranslations[props.depthUnit] || '';
});

// Example format: 'SHALLOW SHADING: 6 FT.'
const headingContent = computed(() => `${props.translations.ITFE_MARINE_MAPS_SAFETY_DEPTH} ${props.safetyDepth} ${translatedDepthUnit.value}`);

/* Methods */
const changeSafetyDepth = (event) => {
  emit('changeSafetyDepth', event?.target?.value);
};
</script>

<template>
  <div
    v-if="!isHeatmap"
    class="safety-depth-slider"
    data-testid="safety-depth-slider"
  >
    <g-heading
      class="safety-depth-slider__heading"
      :content="headingContent"
      size="4"
    />
    <div class="safety-depth-slider__wrap">
      <div class="safety-depth-slider--min">
        {{ state.setMinValue }}
      </div>
      <input
        id="safetyDepth"
        name="safetyDepth"
        type="range"
        :min="state.setMinValue"
        :max="setMaxValue"
        :value="props.safetyDepth"
        :step="setStepValue"
        class="safe-depth-slider__input"
        @input="changeSafetyDepth($event)"
      >
      <div class="safety-depth-slider--max">
        {{ setMaxValue }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@include map-settings('safety-depth-slider');

.safety-depth-slider {

  &--max,
  &--min {
    font-size: 0.8125rem;
  }

  &__wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: normal;
    @include font-primary-weight-regular();

    // stylelint-disable-next-line selector-id-pattern
    #safetyDepth {
      cursor: pointer;
      background-color: $color-gray-80;
      border: 1px solid $color-gray-80;
      border-radius: 5px;
      appearance: none;
      height: 3px;
      outline: none;
      margin: 0 8px;

      &::-webkit-slider-thumb {
        appearance: none;
        width: 15px;
        height: 15px;
        background-color: $color-white;
        border-radius: 50%;
        cursor: pointer;
        @include make-drop-shadow();
      }

      &::-moz-range-thumb {
        appearance: none;
        width: 15px;
        height: 15px;
        background-color: $color-white;
        border-radius: 50%;
        cursor: pointer;
        @include make-drop-shadow();
      }
    }
  }
}
</style>
