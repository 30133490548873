<script setup>
/* Imports */
import {
  ref,
  onBeforeMount,
  computed,
  watch,
} from 'vue';
import {
  useLocalStorage,
  useUrlSearchParams,
  useTitle,
} from '@vueuse/core';
import { useRouteQuery } from '@vueuse/router';

/* Helpers */
import {
  mapGetters,
  mapActions,
  timeIntervalGenerator,
} from './helpers/mainHelpers.js';

/* Composables */
import { useScreenWidth } from './composables/useScreenWidth.js';
import { useProvideMultiple } from './composables/useProvideMultiple.js';

/* Components */
import MapViewer from './components/MapViewer.vue';
import DrawerMobile from './containers/DrawerMobile.vue';
import DrawerDesktop from './containers/DrawerDesktop.vue';
import Interstitial from './components/Interstitial.vue';

/* Initialize composables */
const searchParams = useUrlSearchParams('history');
const lastViewedMapBrand = useLocalStorage('lastViewedMapBrand');
// Initialize composables with query params if they are set
const mapsQueryParam = useRouteQuery('maps', searchParams.maps);
const overlayQueryParam = useRouteQuery('overlay', searchParams.overlay);
const heatmapQueryParam = useRouteQuery('heatmap', searchParams.heatmap);
const { isMobile } = useScreenWidth();
const { provideMultiple } = useProvideMultiple();

/* Store - Getters */
const {
  getLocale: locale,
  getSeoTitle: seoTitle,
  getActiveBrand: activeBrand,
  getTranslations: translations,
  getNoResults: noResults,
  getIsError: isError,
  getIsWorldwideUpdates: isWorldwideUpdates,
  getIsLoading: isLoading,
  getIsInitialMapLocationSet: isInitialMapLocationSet,
  getSearchType: searchType,
  getShowGarminMaps: showGarminMaps,
  getShowInterstitialOverlay: showInterstitialOverlay,
  getWorldwideNumberOfUpdates: worldwideNumberOfUpdates,
  getPolygonNumberOfUpdates: polygonNumberOfUpdates,
  getSelectedPeriod: selectedPeriod,
  getSubdirectory: subdirectory,
} = mapGetters();

/* Store - Actions */
const {
  setInitialState,
  setActiveBrand,
  setChartType,
  setSearchType,
  fetchNumberOfUpdateMultiPolygon,
  fetchWorldwideNumberOfUpdates,
  setSelectedPeriod,
} = mapActions();

/* End date for number of updates period interval - today's day */
const endUpdatesTimestamp = ref(new Date());

/* Provide */
provideMultiple({
  locale,
  translations,
  noResults,
  isError,
  isWorldwideUpdates,
  isHeatmap: computed(() => heatmapQueryParam.value === 'true'),
  isLoading,
  lastViewedMapBrand,
  searchType,
  setSearchType,
  worldwideNumberOfUpdates,
  polygonNumberOfUpdates,
  updatesTimeframes: timeIntervalGenerator(endUpdatesTimestamp.value),
  fetchNumberOfUpdateMultiPolygon,
  fetchWorldwideNumberOfUpdates,
  selectedPeriod,
  setSelectedPeriod,
  showGarminMaps,
  subdirectory,
});

/* Watch for changes to the local storage lastViewedMapBrand value, the maps query param, and the overlay query param */
watch(
  [lastViewedMapBrand, mapsQueryParam, overlayQueryParam],
  ([newLastViewedMapBrandValue, newMapsQueryParamValue, newOverlayQueryParam]) => {
    setActiveBrand({
      localStorageBrandValue: newLastViewedMapBrandValue,
      mapsQueryParamBrandValue: newMapsQueryParamValue,
      overlayQueryParamValue: newOverlayQueryParam,
    });
  },
);
/* Watch -> Set default chart type when switching between garmin and navionics charts */
watch(activeBrand, (newActiveBrand) => {
  if (newActiveBrand === 'garmin') {
    setChartType('nav');
  } else {
    setChartType('nautical');
  }
});

/* Lifecycle Hooks */
onBeforeMount(() => {
  setInitialState();
  useTitle(seoTitle.value);
});
</script>

<template>
  <div
    id="marineMaps"
    role="main"
  >
    <g-global-styles />
    <div
      v-show="!showInterstitialOverlay"
      class="marine__container"
    >
      <!-- Need to get initial map location asynchronously before we load the Leaflet map -->
      <template v-if="isInitialMapLocationSet">
        <MapViewer />
      </template>

      <DrawerDesktop
        v-if="!isMobile"
        :key="mapsQueryParam"
        class="marine__drawer-desktop"
      />
      <DrawerMobile
        v-if="isMobile"
        :key="mapsQueryParam"
        class="marine__drawer-mobile"
      />
    </div>
    <Interstitial v-show="showInterstitialOverlay" />
  </div>
  <router-view v-slot="{ Component }">
    <transition
      name="fade-view"
      mode="out-in"
    >
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<style lang="scss">
/* 100% minus the header and footer */
#app {
  height: auto;

  > div {
    height: 100%;
  }

  @include breakpoint('sm') {
    height: calc(100vh - 190px);
  }

  @include breakpoint('md') {
    height: calc(100vh - 253px);
  }

  @include breakpoint('lg') {
    height: calc(100vh - 149px);
  }
}
</style>

<style lang="scss" scoped>
/* Drawer */
// stylelint-disable-next-line selector-id-pattern
#marineMaps {
  position: relative;
  overflow: hidden;
  height: 100%;
  @extend %font-primary;
}

.marine {

  &__container {
    background-color: $color-gray-85;
    position: relative;
    height: 100%;
  }

  &__drawer {

    &-desktop {
      visibility: hidden;
      @include breakpoint('sm') {
        visibility: visible;
      }
    }

    &-mobile {
      visibility: visible;
      @include breakpoint('sm') {
        visibility: hidden;
      }
    }
  }
}
</style>
