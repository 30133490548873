/* Imports */
import { createStore } from 'vuex';

// Modules
import customerGroups from './customerGroups.js';
import drawer from './drawer.js';
import map from './map.js';
import pricing from './pricing.js';
import search from './search.js';

export const storeSettings = {
  modules: {
    customerGroups,
    drawer,
    map,
    search,
    pricing,
  },
  state: {
    locale: 'en-US',
    translations: {},
    brands: ['garmin', 'another-brand'],
    buyServiceBase: '',
    activeBrand: 'garmin',
    showInstructions: true,
    showInterstitialOverlay: true,
    loading: false,
    chartType: 'nav',
    depthUnit: '',
    safetyDepth: '',
    subdirectory: '',
    showGarminMaps: true,
    showMapSettings: true,
    isMapSettingsOpen: false,
    garminTileDomain: '',
    tileApiKey: null,
    navionicsTileApiKey: null,
    graphQLEndpoint: '',
    contentfulEnvSettings: {},
    pcfApiUrl: '',
    isWorldwideUpdates: true,
    selectedPeriod: '2_years',
  },
  getters: {
    getLocale: (state) => state.locale,
    getCountryCode: (state) => (state?.locale ? state.locale?.split('-', 2)?.[1] : 'US'),
    getTranslations: (state) => state.translations,
    getBrands: (state) => state.brands,
    getBuyServiceBase: (state) => state.buyServiceBase,
    getActiveBrand: (state) => state.activeBrand,
    getShowGarminMaps: (state, getters) => getters.getActiveBrand === 'garmin',
    getSeoTitle: (state, getters) => getters.getTranslations?.ITFE_MARINE_MAPS_SEO_TITLE || 'Garmin | Marine Maps',
    getShowInstructions: (currentState) => currentState.showInstructions,
    getShowInterstitialOverlay: (currentState) => currentState.showInterstitialOverlay,
    getLoading: (currentState) => currentState.loading,
    getChartType: (currentState) => currentState.chartType,
    getChartDepth: (currentState) => currentState.depthUnit,
    getSafetyDepth: (currentState) => currentState.safetyDepth,
    getShowMapSettings: (currentState) => currentState.showMapSettings,
    getIsMapSettingsOpen: (currentState) => currentState.isMapSettingsOpen,
    getGarminTileDomain: (currentState) => currentState.garminTileDomain,
    getTileApiKey: (currentState) => currentState.tileApiKey,
    getNavionicsTileApiKey: (currentState) => currentState.navionicsTileApiKey,
    getSubdirectory: (currentState) => currentState.subdirectory,
    getGraphQLEndpoint: (currentState) => currentState.graphQLEndpoint,
    getContentfulEnvSettings: (currentState) => currentState.contentfulEnvSettings,
    getPcfApiUrl: (currentState) => currentState.pcfApiUrl,
    getIsWorldwideUpdates: (currentState) => currentState.isWorldwideUpdates,
    getSelectedPeriod: (currentState) => currentState.selectedPeriod,
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    },
    setTranslations(state, translations) {
      state.translations = translations;
    },
    setActiveBrand(state, activeBrand) {
      state.activeBrand = activeBrand;
    },
    setBuyServiceBase: (state, value) => {
      state.buyServiceBase = value;
    },
    setShowGarminMaps(state, showGarminMaps) {
      state.showGarminMaps = showGarminMaps;
    },
    setShowInstructions(state, showInstructions) {
      state.showInstructions = showInstructions;
    },
    setShowInterstitialOverlay(state, showInterstitialOverlay) {
      state.showInterstitialOverlay = showInterstitialOverlay;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setChartType(state, payload) {
      state.chartType = payload;
    },
    setChartDepth(state, payload) {
      state.depthUnit = payload;
    },
    setSafetyDepth(state, payload) {
      state.safetyDepth = payload;
    },
    setSubdirectory(state, payload) {
      state.subdirectory = payload;
    },
    setShowMapSettings(state, payload) {
      state.showMapSettings = payload;
    },
    setIsMapSettingsOpen(state, payload) {
      state.isMapSettingsOpen = payload;
    },
    setGarminTileDomain(state, payload) {
      state.garminTileDomain = payload;
    },
    setTileApiKey(state, payload) {
      state.tileApiKey = payload;
    },
    setNavionicsTileApiKey(state, payload) {
      state.navionicsTileApiKey = payload;
    },
    setGraphQLEndpoint(state, payload) {
      state.graphQLEndpoint = payload;
    },
    setContentfulEnvSettings(state, payload) {
      state.contentfulEnvSettings = payload;
    },
    setPcfApiUrl(state, payload) {
      state.pcfApiUrl = payload;
    },
    setIsWorldwideUpdates(state, payload) {
      state.isWorldwideUpdates = payload;
    },
    setSelectedPeriod(state, payload) {
      state.selectedPeriod = payload;
    },
  },
  actions: {
    setLocale({ commit }, payload) {
      commit('setLocale', payload);
    },
    setTranslations({ commit }, payload) {
      commit('setTranslations', payload);
    },
    setShowGarminMaps({ commit }, payload) {
      commit('setShowGarminMaps', payload);
    },
    async setShowInstructions({ commit }, payload) {
      commit('setShowInstructions', payload);
    },
    async setLoading({ commit }, payload) {
      commit('setLoading', payload);
    },
    setChartType({ commit }, payload) {
      commit('setChartType', payload);
    },
    setChartDepth({ commit }, payload) {
      commit('setChartDepth', payload);
    },
    setSafetyDepth({ commit }, payload) {
      commit('setSafetyDepth', payload);
    },
    setShowMapSettings({ commit }, payload) {
      commit('setShowMapSettings', payload);
    },
    setIsMapSettingsOpen({ commit }, payload) {
      commit('setIsMapSettingsOpen', payload);
    },
    setSelectedPeriod({ commit }, payload) {
      commit('setSelectedPeriod', payload);
    },
    setShowInterstitialOverlay({ commit }, payload) {
      commit('setShowInterstitialOverlay', payload);
    },
    setActiveBrand({ commit, getters }, payload) {
      const { getBrands } = getters;
      const {
        localStorageBrandValue,
        mapsQueryParamBrandValue,
        overlayQueryParamValue,
      } = payload;

      const validOverlayValues = ['true', 'false'];
      const hasValidOverlayValue = (overlayVal) => validOverlayValues.includes(overlayVal);
      const hasValidBrand = (brand) => getBrands.includes(brand);
      const hasEqualValidBrands = localStorageBrandValue === mapsQueryParamBrandValue && hasValidBrand(localStorageBrandValue);

      if (overlayQueryParamValue === 'true') {
        commit('setShowInterstitialOverlay', true);
      }

      if (overlayQueryParamValue === 'false') {
        if (!hasValidBrand(localStorageBrandValue) && hasValidBrand(mapsQueryParamBrandValue)) {
          commit('setActiveBrand', mapsQueryParamBrandValue);
          commit('setShowInterstitialOverlay', false);
        } else if (!hasValidBrand(mapsQueryParamBrandValue) && hasValidBrand(localStorageBrandValue)) {
          commit('setActiveBrand', localStorageBrandValue);
          commit('setShowInterstitialOverlay', false);
        } else if (hasEqualValidBrands) {
          commit('setActiveBrand', localStorageBrandValue);
          commit('setShowInterstitialOverlay', false);
        } else {
          commit('setShowInterstitialOverlay', true);
        }
      }

      if (!hasValidOverlayValue(overlayQueryParamValue)) {
        if (!hasValidBrand(mapsQueryParamBrandValue) && hasValidBrand(localStorageBrandValue)) {
          commit('setActiveBrand', localStorageBrandValue);
          commit('setShowInterstitialOverlay', false);
        } else if (hasEqualValidBrands) {
          commit('setActiveBrand', localStorageBrandValue);
          commit('setShowInterstitialOverlay', false);
        } else {
          commit('setShowInterstitialOverlay', true);
        }
      }
    },
    async setInitialState({ commit, dispatch }) {
      const {
        buyServiceBase = '',
        locale = 'en-US',
        translations = {},
        subdirectory,
        garminTileDomain = '',
        apiKey = '',
        navionicsTileApiKey = '',
        graphQLEndpoint = '',
        contentfulEnvSettings = {},
        pcfApiUrl = '',
      } = window.AppData;

      // Get initial URL query params
      const initialSearchParams = new URLSearchParams(window?.location?.search);
      const mapsQueryParamBrandValue = initialSearchParams.get('maps');
      const overlayQueryParamValue = initialSearchParams.get('overlay');

      // Get last viewed map brand from local storage
      const localStorageBrandValue = localStorage.getItem('lastViewedMapBrand');

      dispatch('setActiveBrand', {
        localStorageBrandValue,
        mapsQueryParamBrandValue,
        overlayQueryParamValue,
      });

      dispatch('setInitialMapLocation');
      commit('setBuyServiceBase', buyServiceBase);
      commit('setTranslations', translations);
      commit('setLocale', locale);
      commit('setSubdirectory', subdirectory);
      commit('setGarminTileDomain', garminTileDomain);
      commit('setTileApiKey', apiKey);
      commit('setNavionicsTileApiKey', navionicsTileApiKey);
      commit('setGraphQLEndpoint', graphQLEndpoint);
      commit('setContentfulEnvSettings', contentfulEnvSettings);
      commit('setPcfApiUrl', pcfApiUrl);

      const chartDepth = locale === 'en-US' ? 'ft' : 'm';
      const safetyDepth = locale === 'en-US' ? '6' : '2';

      commit('setChartDepth', chartDepth);
      commit('setSafetyDepth', safetyDepth);
    },
    setIsWorldwideUpdates({ commit }, payload) {
      commit('setIsWorldwideUpdates', payload);
    },
  },
};

export default createStore(storeSettings);
