<script setup>
/* Props */
const props = defineProps({
  type: {
    type: String,
    default: 'dark',
  },
});
</script>

<template>
  <div class="loader">
    <g-loader
      class="loader__spinner"
      :type="props.type"
    />
  </div>
</template>

<style lang="scss" scoped>
.loader {
  position: absolute;
  inset: 0;
  background: $color-gray-loader;

  &__spinner {
    width: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}
</style>
