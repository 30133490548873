<script setup>
/* Imports */
import { inject, computed } from 'vue';

/* Components */
import Results from '../components/Results.vue';
import Search from '../components/Search.vue';
import MapNumberOfUpdates from '../components/MapNumberOfUpdates.vue';

/* Inject */
const isWorldwideUpdates = inject('isWorldwideUpdates');
const isLoading = inject('isLoading');
const isHeatmap = inject('isHeatmap');

/* Computed - Should show the map number of updates */
const shouldShowMapNumberOfUpdates = computed(() => isWorldwideUpdates.value && !isLoading.value && isHeatmap.value);
</script>

<template>
  <div
    id="drawer-desktop"
    class="drawer"
    data-testid="drawer-desktop"
  >
    <div class="drawer__results">
      <div class="drawer__scrollbar">
        <div class="drawer__results__container">
          <Search />
          <MapNumberOfUpdates
            v-if="shouldShowMapNumberOfUpdates"
            class="drawer__map-number-of-updates"
          />
          <Results />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.drawer {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  height: 100%;
  max-height: 100%;
  display: flex;
  z-index: 1000; // Allows drawer to render above leaflet map
  transition: margin-right 0.5s cubic-bezier(0.52, -0.57, 0.42, 1.57) 0s;
  -webkit-overflow-scrolling: touch;
  width: $drawerWidthTablet;
  margin-right: -#{$drawerWidthTablet};
  @include breakpoint('md') {
    width: $drawerWidthDesktop;
    margin-right: -#{$drawerWidthDesktop};
  }

  &__scrollbar {
    height: 100%;
    max-height: 100%;
  }

  &__map-number-of-updates {
    margin-bottom: 1rem;
  }

  &__results {
    background: $color-white;
    width: 100%;
    height: 100%;
    @include make-drop-shadow();
    box-sizing: content-box;

    &__container {
      background-color: $color-gray-95;
      height: calc(100% - 40px);
      max-height: 100%;
      padding: 1.25rem;
      overflow: hidden auto;
    }
  }
}
</style>
