import { createApp } from 'vue';
import vue3TouchEvents from 'vue3-touch-events';

import App from './App.vue';
import router from './router/router.js';
import { sentry } from './modules/sentry.js';
import store from './store/globalStore.js';

/* eslint-disable no-unused-vars */
import * as registerComponents from './modules/registerComponents.js';
/* eslint-enable no-unused-vars */

import leafletComponents from './modules/leafletComponents.js';
import { setupGlobalProperties } from './modules/globalProperties.js';

const app = createApp(App);

setupGlobalProperties(app);
app.use(router);
app.use(store);
// Initialize sentry
sentry(app);
// Handle leaflet and make it global
app.use(leafletComponents);
// Initialize vue3-touch-events
app.use(vue3TouchEvents);

app.mount('#app');
