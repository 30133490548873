<script setup>
/* Imports */
import { computed } from 'vue';

/* Icons */
import ArrowDown from '@garmin/regalia/icons/arrow-down/icon.vue';

/* Helpers */
import {
  mapGetters,
  mapActions,
} from '../helpers/mainHelpers.js';

/* Composables */
import { useScreenWidth } from '../composables/useScreenWidth.js';

/* Components */
import ResultBlock from './ResultBlock.vue';
import LakeDetails from './LakeDetails.vue';
import Loader from './Loader.vue';
import SearchErrors from './SearchErrors.vue';

/* Initialize composables */
const { isMobile } = useScreenWidth();

/* Store - Getters */
const {
  getMaps: maps,
  getIsLoading: isLoading,
  getTranslations: translations,
  getActiveLake: activeLake,
  getShowLakeInfo: showLakeInfo,
  getShowLakeFeatures: showFeatures,
} = mapGetters();

/* Store - Actions */
const {
  setShowLakeFeatures,
} = mapActions();

/* Computed */
const showLakeDetails = computed(() => activeLake.value && showLakeInfo.value);
const showLakeTable = computed(() => activeLake.value && showFeatures.value);
const activeLakeKey = computed(() => activeLake.value?.id);

/* Handle computed arrow classes */
const drawerArrowClasses = computed(() => ({
  'results__lake-details__lakes': true,
  'results__lake-details__arrow--active': showFeatures.value,
}));

/* Lake Info */
const lakeInfo = computed(() => {
  const lakeName = activeLake.value?.name;
  const lakeStates = activeLake.value?.states.join(', ');
  return `${lakeName} - ${lakeStates}`;
});

const toggleFeatures = () => {
  setShowLakeFeatures(!showFeatures.value);
};
</script>

<template>
  <div
    class="results"
    data-testid="results"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="showLakeDetails"
        :key="activeLakeKey"
        class="results__lake-details"
      >
        <g-heading
          size="4"
          :content="translations.ITFE_MARINE_MAPS_RESULTS"
        />
        <div
          class="results__lake-details__description"
          data-testid="lake-details"
        >
          <div
            class="results__lake-details__description--header"
            tabindex="0"
            @click="toggleFeatures()"
            @keydown.enter.prevent="toggleFeatures()"
            @keydown.space.prevent="toggleFeatures()"
          >
            <g-heading
              size="3"
              :content="lakeInfo"
            >
              <span :class="drawerArrowClasses">
                <ArrowDown
                  class="results__lake-details__arrow"
                  :aria-label="translations.ITFE_MARINE_MAPS_FEATURES_ARROW"
                />
              </span>
            </g-heading>
          </div>
          <div class="results__lake-details__description--body">
            <LakeDetails
              v-if="showLakeTable"
              :active-lake="activeLake"
              :translations="translations"
              :is-loading="isLoading"
              :show-lake-info="showLakeInfo"
            />
          </div>
        </div>
      </div>
    </transition>

    <g-heading
      v-if="showLakeDetails"
      size="4"
      :content="translations.ITFE_MARINE_MAPS_LAKE_INCLUDED"
      class="results__title"
    />

    <g-heading
      v-else
      size="4"
      :content="translations.ITFE_MARINE_MAPS_PRODUCTS"
      class="results__title"
    />

    <Loader
      v-if="isLoading"
      data-testid="loader-results"
    />

    <ResultBlock
      :maps="maps"
      :is-loading="isLoading"
    />

    <SearchErrors v-if="isMobile" />
  </div>
</template>

<style lang="scss" scoped>
.results {

  &__lake-details {

    &__description {
      background: $color-white;
      border: 1px solid $color-gray-85;
      border-top: none;
      @include make-drop-shadow();
      height: auto;
      min-height: 45px;
      margin-bottom: 1.25rem;

      :deep(.g__heading) {

        h3 {
          margin: 0;
        }
      }

      &--header {
        padding: 1rem 1.25rem 1rem 1rem;
        cursor: pointer;
      }
    }

    &__lakes {
      float: right;
      vertical-align: middle;
    }

    &__arrow {
      width: 0.875rem;
      height: 0.875rem;
      position: relative;
      transition: transform 0.2s ease;
      left: 3px;
      top: 0.25rem;

      &--active {

        .results__lake-details__arrow {
          transition: transform 0.2s ease;
          transform: rotate(-180deg);
          left: -2px;
        }
      }
    }
  }

  &.fade-enter {
    transition: opacity 0.2s;
  }

  &.fade-enter-active,
  &.fade-leave-to,
  &.fade-leave-active {
    opacity: 0;
  }

  &__title {
    display: none;
    text-transform: uppercase;

    @include breakpoint('sm') {
      display: block;
    }
  }
}

:deep(.g__heading) {

  h4 {
    margin-top: 0;
    margin-bottom: 0.75rem;
    text-transform: uppercase;
    font-size: 1.25rem;

    @include breakpoint('sm') {
      margin-bottom: 1.25rem;
    }
  }
}
</style>
