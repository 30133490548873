/* Imports */
import { getAndUpText } from '@garmin/locale-and-up-text';

const defaultPricingData = Object.freeze({
  listPrice: null,
  salePrice: null,
  savings: null,
  unformatted: {
    listPrice: null,
    salePrice: null,
    savings: null,
  },
  andUpText: null,
  skuPromotions: {},
});

export default {
  actions: {
    formatPricingData: ({ rootGetters }, payload) => {
      const { getLocale } = rootGetters;
      const pricingData = payload || [];

      const pricedSkusResult = pricingData.map((skuPriceData) => skuPriceData.value);

      // If there are no skus with prices, bail out early with null pricing data object
      // eslint-disable-next-line max-len
      if (pricedSkusResult.every((skuPriceData) => skuPriceData === null) || pricedSkusResult.every((skuPriceData) => skuPriceData?.listPrice === null)) {
        return { ...defaultPricingData };
      }

      // We need to get a list of all the sku prices to determine if they are all the same price
      // Use the salePrice if available, otherwise use the listPrice
      const skuPrices = pricedSkusResult.map((skuPriceData) => (skuPriceData?.salePrice?.price ?? skuPriceData?.listPrice?.price ?? null));

      // Handle andUpText logic
      const computeAndUpText = (prices, locale) => {
        const filterOutNullValues = (price) => price;
        // If there are any variations in the related sku prices, we'll add "and up" text
        if (prices.filter(filterOutNullValues).some((price) => price.toString() !== skuPrices[0].toString())) {
          return getAndUpText(locale);
        }
        return null;
      };
      const andUpText = computeAndUpText(skuPrices, getLocale);

      // Handle sku promotions
      const consolidatedSkuPromotions = {};
      const consolidateSkuPromotions = (skuPriceData) => {
        if (skuPriceData?.partNumber && skuPriceData?.appliedPromotionGuids?.[0]) {
          consolidatedSkuPromotions[skuPriceData.partNumber] = [{
            promotionGuid: skuPriceData.appliedPromotionGuids[0],
          }];
        }
      };

      // Loop over all priced sku results and consolidate the sku promotions into 1 object
      pricedSkusResult.forEach(consolidateSkuPromotions);

      // Handle formatting sku prices
      const formatSkuPrices = (skuPriceData) => ({
        listPrice: skuPriceData?.listPrice?.formattedPrice ?? null,
        salePrice: skuPriceData?.salePrice?.formattedPrice ?? null,
        savings: skuPriceData?.savings?.formattedPrice ?? null,
        unformatted: {
          listPrice: skuPriceData?.listPrice?.price ?? null,
          salePrice: skuPriceData?.salePrice?.price ?? null,
          savings: skuPriceData?.savings?.price ?? null,
        },
      });

      // Handle consolidating sku prices
      const consolidateSkuPrices = (prev, current) => {
        // Use salePrice if it exists, otherwise, use the listPrice
        const currentPrice = current?.unformatted?.salePrice ? current.unformatted.salePrice : current?.unformatted?.listPrice;
        const prevPrice = prev?.unformatted?.salePrice ? prev?.unformatted?.salePrice : prev?.unformatted?.listPrice;

        // Consolidate the sku prices to the lowest price
        return prevPrice < currentPrice ? prev : current;
      };

      const formattedPricedSkus = pricedSkusResult
        .map(formatSkuPrices)
        .reduce(consolidateSkuPrices);

      const pricingDataResult = {
        andUpText,
        ...formattedPricedSkus,
        skuPromotions: consolidatedSkuPromotions,
      };

      return pricingDataResult;
    },
    async fetchPricingData({ rootGetters }, payload) {
      const {
        getCustomerGroups,
        getCountryCode,
        getBuyServiceBase,
        getLocale,
      } = rootGetters;
      const sku = payload;

      const queryParams = new URLSearchParams();
      queryParams.append('locale', getLocale);
      /**
       * If there are multiple customerGroups, they will be divided by a |
       * new URLSearchParams() will convert | to %7C which the pricing APIs expect when there are multiple customer groups
       */
      queryParams.append('customerGroup', getCustomerGroups);

      const locationHref = window?.location?.href;
      if (locationHref.includes('cdncache=false')) queryParams.append('cdncache', false);
      const requestUrl = `${getBuyServiceBase}/pricing-proxy-services/countries/${getCountryCode}/skus/${sku}/price?${queryParams}`;
      try {
        /**
         * We need to send cookies with this request to pass along the GarminBuyReferrer cookie
         * Because of this, we are using fetch with credentials: 'include' instead of axios
         * axios with the withCredentials: true setting is not correctly sending cookies
         * with the request. More info here: https://github.com/axios/axios/issues/2149
         */
        const response = await fetch(requestUrl, {
          method: 'get',
          credentials: 'include',
        });

        const priceData = await response.json();
        return priceData;
      } catch (error) {
        throw new Error(`Error encountered in Pricing - ${error.message}`);
      }
    },
    async fetchPrice({ dispatch }, payload) {
      const skus = payload;
      await dispatch('setCustomerGroups');
      if (!skus.length) {
        // If there are no skus, return default pricing object
        return defaultPricingData;
      }

      try {
        const promises = skus.map((sku) => dispatch('fetchPricingData', sku));
        const pricingResponses = await Promise.allSettled(promises);

        // Add sku back to pricing responses
        const pricingResults = pricingResponses.map((result, index) => ({ ...result, partNumber: skus[index] }));
        // Format pricing data
        const [formattedPricingData] = await Promise.all([dispatch('formatPricingData', pricingResults)]);
        return formattedPricingData;
      } catch {
        // eslint-disable-next-line no-console
        console.log('Error fetching pricing data');
        return defaultPricingData;
      }
    },
  },
};
