/* Imports */
import {
  h,
  resolveComponent,
} from 'vue';

export default [{
  path: '/:locale/marine',
  component: {
    render() {
      return h(resolveComponent('router-view'));
    },
  },
  children: [{
    path: '/:locale/:path/:pathMatch(.*)*',
    redirect: (to) => {
      const { locale } = to.params;
      return {
        path: `/${locale ?? 'en-US'}/${window?.AppData?.subdirectory ?? 'marine'}`,
      };
    },
  }],
}];
